<template>
    <section>
        <!-- activation -->
        <!-- <b-button v-b-toggle.sidebar-right>modification style</b-button> -->
        <!-- sidebar -->

        <b-sidebar id="sidebar-right" right shadow>
            <!-- footer -->
            <template v-if="block.data" class="no-padding" #footer="{}">
            
                <div class="d-flex bg-dark text-light align-items-center px-3 py-2">
                    <strong class="mr-auto">
                        <i class="fa fa-home mx-2"></i>
                        <i class="fa fa-home mx-2"></i>
                        <i class="fa fa-home mx-2"></i>
                        <i class="fa fa-home mx-2"></i>
                    </strong>
                    <b-button size="sm" @click="save()">Enregistrer</b-button>
                </div>
            </template>
            <!-- contenu du sidebar -->
            <div v-if="block.data" class="px-3 py-2">
                <b-dropdown id="ddown12" v-if="block.data" :text="block.element" variant="outline-info">
                    <b-dropdown-item>Settings</b-dropdown-item>
                </b-dropdown>
                <b-tabs content-class="mt-3">
                    
                    <!-- tabs-Style -->
                    <b-tab title="style" active>
                        <h6>Arrière-plan</h6>
                        <hr>
                        <!-- background-color -->
                        <b-form-group label="Couleur"  label-for="input9">
                            <input class="pointer" type="color" id="head" name="head" v-model='style.color'>
                            <label class="mx-4" for="head">{{style.backgroundColor}}</label>
                        </b-form-group>
                        <!-- background-image -->
                        <b-form-group label="Image"  label-for="input9">
                            <div class="d-flex">
                                <add-image 
                                :modal="'Ajout image'"
                                @saveImage="block.image = $event['image'],update" 
                                />
                                <!-- <label class="mx-4" for="head">{{block.data[0].image}}</label> -->
                            </div>
                        </b-form-group>

                        <h6>Texte</h6>
                        <hr>
                        <!-- text-color -->
                        <b-form-group label="Couleur"  label-for="input9">
                            <input class="pointer" type="color" id="head" name="head" v-model='style.textColor'>
                            <label class="mx-4" for="head">{{style.textColor}}</label>
                        </b-form-group>
                        <!-- text-size -->
                        <b-form-group label="Taille"  label-for="input9">
                            <b-form-select placeholder="taille">
                                
                            </b-form-select>
                            <label class="mx-4" for="head">{{style.textSize}}</label>
                        </b-form-group>

                    </b-tab>
                    <!-- Contenu -tabs -->
                    <b-tab title="contenu">
                        <h6>Eléments à afficher (card)</h6>
                        <hr>
                        <b-form-checkbox
                            id="description"
                            v-model="description"
                            name="description"
                            >
                            Description
                        </b-form-checkbox>
                        <b-form-checkbox
                            id="title"
                            v-model="title"
                            name="title"
                            >
                            titre
                        </b-form-checkbox>
                        <b-form-checkbox
                            id="price"
                            v-model="price"
                            name="prix"
                            >
                            prix
                        </b-form-checkbox>
                    </b-tab>
                    
                    <b-tab title="animation">
                        <h6>Animation au scroll</h6>
                        <hr>
                        <!-- animation -->
                        <b-form-group label="Type d'effet"  label-for="input9">
                            <b-form-select class="pointer" type="text" id="head" name="head" :options="params.animation.type" v-model='animation.type'/>
                            <label class="mx-4 text-muted" for="head">{{animation.type}}</label>
                        </b-form-group>
                        <b-form-group label="Durée (sec)"  label-for="input9">
                            <b-input class="pointer" type="number" id="head" name="head" v-model='animation.duration' max="10"/>
                            <label class="mx-4 text-muted" for="head">{{animation.duration}}</label>
                        </b-form-group>
                    </b-tab>
                </b-tabs>
            </div>
        </b-sidebar>
    </section>
</template>
<script>
import addImage from './functions/add-image.vue';
import params from './json/style-parameters.json';
export default {
    name:"style-sidebar",
    components:{
        addImage,
    },
    props:{
        block:Object
    },
    data(){
        return{
            params: params,
            selectedElement:{},
            style:{
                backgroundColor:"#e66465",
                textColor:"#e66465",
                image:"url"
            },
            content:{
                card:{
                    description:true,
                    title:true,
                    price:true
                }
            },
            animation:{
                type:"none",
                duration:"000"
            }
        }
    },
    methods:{
        sidebar(){
            console.log(this);
        },
        save(){
            console.log("clic dans sidebar");
            this.$emit('saveStyle', {
                backgroundColor:this.style.backgroundColor,
                backgroundImage:this.style.image,
                textColor:this.style.textColor,
                animationType:this.animation.type,
                animationDuration:this.animation.duration*1000
            })
        },
        saveImage(payload,elem){
            console.log(elem)
            elem = payload.image
        },
        update(){
            this.$emit('update_ssb')
        }
    },
    mounted(){
        console.log('valeur par défaut',this.defaultValue);
    }
}
</script>
<style scoped>

</style>