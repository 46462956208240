function d_properties(c, block, section) {
    switch (c) {
        case "carousel":
            return {
                slides: block.data.slides,
                editable: this.modeEdit
            };
        case "carousel-meb":
            //ito tsy mety
            return {
                slides: block.data['carousel-meb'],
                editable: this.modeEdit
            };
            
        case "meb-carousel":
            return {
                editable: this.modeEdit,
                text1:block.data.text1,
                image1: block.data.image1,
                text2:block.data.text2,
                image2: block.data.image2,
                text3:block.data.text3,
                image3: block.data.image3
            }

        case "horizontal-banner":
            return {
                text: block.data["text"],
                image: block["image"],
                // edit: this.modeEdit,
                // editable:this.modeEdit,
                modeEdit:this.modeEdit,
                sectionTitle: section.title,
            };

        case "product-card":
            return {
                "scrollcards": block.data.scrollcards,
                "entitytype": block.entitytype,
                "display": block.data.display,
                "title": 'card-title',
                "price": 'card-price',
                "editable": this.modeEdit,
                "description": 'card-description',
                "column": "col-md-3",
                "sectionTitle": section.title,
                "data-aos":block.animationType,
                "data-duration":block.animationDuration
            }

        case "circle-card-meb":
            var ccprops = {}
            if(block.entitytype){
                
                ccprops = {
                "scrollcards": block.data.scrollcards,
                "display": block.data.display,
                "entitytype": block.entitytype,
                "title": block.data.collection['card-title'],
                "description": block.data.collection['card-title'],
                "image": block.data.collection['card-image-url'],
                "editable": this.modeEdit,
                "column":"col-md-3",
                "sectionTitle":section.title
                }
            }else{
                ccprops = {
                    card:block.data.card,
                    display:block.data.display,
                    scrollcards:block.data.scrollcards,
                    editable:this.modeEdit,
                    showDescription:block.data['showDescription'],
                    title:"card-title",
                    image:"card-image-url",
                    description:"card-description",
                    column:"col-md-3",
                    sectionTitle:section.title
                }
            }
            return ccprops
        case "gold-card-meb":
            var props = {}
            if (block.entitytype) {
                props = {
                    scrollcards: block.data.scrollcards,
                    descdisplay: block.data.display,
                    entitytype: block.entitytype,
                    title: block.data.collection['card-title'],
                    description: block.data.collection['card-title'],
                    image: block.data.collection['card-image-url'],
                    column: "col-md-3",
                    sectionTitle: section.title,
                    editable: this.modeEdit
                }
            }else{
                props = {
                    card:block.data.card,
                    scrollcards:block.data.scrollcards,
                    editable:this.modeEdit,
                    descdisplay:block.data.display,
                    title:'card-title',
                    image:'card-image-url',
                    description:'card-description',
                    column:'col-md-3',
                    sectionTitle:section.title
                }  
            }
            return props
        case "meb-biographie":
            return{
                "sectionTitle":section.title,
                "text":block.data.text,
                "editable":this.modeEdit,
                "image":{
                    "originalImage":block.data["original-image-url"],
                    "webpImage":block.data["webp-image-url"]
                }
            }
        case "product-details":
            return{
                "editable":this.modeEdit,
                "title":block.data['card-title'],
                "description":block.data['card-description'],
                "price":block.data['card-price'],
                "sectionTitle":section.title,
                "text":block.data.text
            }
        case "text-type-1":
            return{
                "data":block.data,
                "editable":this.modeEdit,
                "sectionTitle":section.title  
            }

        case "shop-meb":
            return{
                "text":block.data.description['text'],
                
            }
        case "services-meb":
            return{
                
            }
        case "profil-pr":
            return{
                "text_1": block.data.text_1,
                "text_2": block.data.text_2,
                "editable":this.modeEdit,
                
            }
        case "carriere-pr":
            return{
                "editable":this.modeEdit,
                "title_1":block.data.title_1,
                "title_2":block.data.title_2,
                "title_3":block.data.title_3,
                "description_1":block.data.description_1,
                "description_2":block.data.description_2,
                "description_3":block.data.description_3,                
            }
        case "blue-and-orange-card-pr":
            return{
                
            }
        case "text-tt":
            return{
                "data":block.data.text,
                "modeEdit":this.modeEdit
            }
        default:
            return {};
    }
}
export { d_properties }