<template>
    <div class="details-wrapper pt-0" v-if="page">
      <styleSidebar v-if="isVisible"  :key="ssb_key" @update_ssb="ssb_key++" :block="selected_block"/>
      <div class="container">
        <!-- insertion des "sections" et des conditions d'affichage -->
        <div class="row">
          <div class="col-md-12" >
  <div class="no-image my-5">
      <div class="d-flex justify-content-end" v-if="isVisible">
        <b-button  v-if="!modeEdit" class="btn btn-info my-4"  @click="modeEdit = true"  >
          <i class="fa fa-pencil"></i> Editer la Page
        </b-button>
      </div>
    
    
    <b-button  v-if="modeEdit && isVisible"  class="btn btn-info my-4"  @click="changeLog('z')" >
      <i class="fa fa-pencil"></i> Undo
    </b-button>
    <b-button v-if="modeEdit && isVisible" class="btn btn-info my-4" @click="changeLog('y')"  >
      <i class="fa fa-pencil"></i> Rendo
    </b-button>

    <card-pagination :class="{ selectSection: modeEdit }" v-for="(section, sectionId) in page" :key="sectionId" >
      <div v-if="modeEdit && isVisible" class="btn-group crud-section" role="group"  aria-label="crud-section"  >
        <b-dropdown  size="sm" variant="link"  class="btn btn-primary"  toggle-class="text-decoration-none"
          no-caret  title="Gérer les colonnes"  >
          <template #button-content>
            <i class="mdi mdi mdi-view-column" style="color: #fff"></i>
          </template>
          <b-dropdown-item>
            <div class="btn-group" role="group" aria-label="Basic example">
              <button  type="button"  class="btn btn-outline-secondary"  @click="changeGrid(section.blocks, ['col-md-12'])">
                <i class="mdi mdi-crop-portrait"></i>
              </button>
              <button type="button" class="btn btn-outline-secondary" @click="changeGrid(section.blocks, ['col-md-4', 'col-md-8'])">
                <i class="fa fa-columns"></i>
              </button>
              <button  type="button" class="btn btn-outline-secondary" @click="changeGrid(section.blocks, [ 'col-md-3',  'col-md-6',  'col-md-3',  ])">
                <i class="mdi mdi-view-column"></i>
              </button>
            </div>
          </b-dropdown-item>
        </b-dropdown>
        <button type="button" class="btn btn-primary"  @click="deleteSection(section)" title="Supprimer"
        >
          <i class="mdi mdi-delete"></i>
        </button>
        <button type="button" class="btn btn-primary" title="Modifier">
          <i class="mdi mdi-pen"></i>
        </button>
        <!-- drag and drop -->
        <button type="button" class="btn btn-primary handle" style="cursor: move"  title="Déplacer"
        >
          <i class="mdi mdi-arrow-all"></i>
        </button>
      </div>
      <div v-for="(blocks, blockIsd) in section.blocks"  class="col blocks" :class="blocks.column" :key="blockIsd" >
      <draggable :list="blocks.data" group="composant" :class="{drag:modeEdit}" >
        <div v-for="(block, blockId) in blocks.data" :key="blockId">
          <b-button  v-if="modeEdit && isVisible"  @click="removeComponent(blocks.data,block)">X</b-button>

          <b-button v-if="modeEdit && isVisible" v-b-toggle.sidebar-right  @click="selected_block =block ,ssb_key++"> <i class="fa fa-pencil" ></i> </b-button>

          <component
            :is="block.element"
            v-bind="_properties(block.element, block, section)"
            v-dynamic-events:[{block:block,data:block.data,section:section}]="
              Allevent[block.element]
            "
            :key="blockId+''+ssb_key"
          >
          </component>
        </div>
      </draggable>
      </div>
      <add-empty-section
          v-if="modeEdit"
          :ulid="section.ulid"
          @addEmpty="
            sectionAddEmpty(
              page,
              section,
              $event['section'],
              $event['blocks']
            )
          "
        ></add-empty-section>
    </card-pagination>
       </div>
      </div>
    </div>
    <button @click="putPage()">Enregistrer les modifications</button>
    </div>
  </div>
</template>
<script>
import pageJson from "../../json/section-edena-data.json";
import addEmptySection from "../../../components/functions/add-empty-section.vue";
import draggable from "vuedraggable";
import event from "../preview/event-list.json";
import { d_properties } from "../preview/properties.js";
// importation des methods
// import {getSectionPage, editBanner,changePicture,editCard,carouselNewSlide,carouselMebNewSlide,NewCardMeb,textRemove,textPush,cardSave,cardProductSave,savePageEdit,deleteSection,changeGrid,removeComponent,loadLog,saveLog,cancelAutoUpdate,changeLog,changeText,changeTextHorizontalBanner,ChangeCarrierePr,changeCurImage,changeImageOriginalWebp,changeTextWithElement} from "../preview/method.js";
import { methodsMixin } from '../preview/mixins/methodsMixin.js'

import styleSidebar from '../../../components/style-sidebar.vue';

import CardPagination from "../../../components/pagination/card-pagination.vue";
import axios from 'axios';


export default {
  name: "preview",
  mixins:[methodsMixin],
  components: {
    "carousel": () => import("../../../components/element/carousel.vue"),
    "carousel-meb": () =>import("../../../components/element/carousel-meb.vue"),
    "horizontal-banner": () =>import("../../../components/element/horizontal-banner.vue"),
    "product-card": () =>import("../../../components/element/product-card.vue"),
    "circle-card-meb": () =>import("../../../components/element/circle-card-meb.vue"),
    "card-title-description": () =>import("../../../components/element/card-title-description.vue"),
    "gold-card-meb": () =>import("../../../components/element/gold-card-meb.vue"),
    "meb-biographie": () =>import("../../../components/element/meb/meb-biographie.vue"),
    "stringtext": () => import("../../../components/element/string-text.vue"),
    "textType1": () => import("../../../components/element/text-type-1.vue"),
    "shop-meb": () => import("../../../components/element/shop-meb.vue"),
    "services-meb": () => import("../../../components/element/services-meb.vue"),
    "product-details": () =>import("../../../components/element/product-details.vue"),
    "profil-pr": () =>import("../../../components/element/profil-pr.vue"),
    "carriere-pr": () =>import("../../../components/element/carriere-pr.vue"),
    "blue-and-orange-card-pr": () =>import("../../../components/element/blue-and-orange-card-pr.vue"),
    "text-tt": () =>import("../../../components/element/text-tt.vue"),
    // Mon échappée belle
    "meb-carousel": () => import("../../../components/element/meb/meb-carousel.vue"),
    CardPagination,
    addEmptySection,
    styleSidebar,
    draggable
  },
  //https://fr.vuejs.org/v2/guide/custom-directive.html
  directives: {
    DynamicEvents: {
      bind: (el, binding, vnode) => {
        const allEvents = binding.value;
        Object.keys(allEvents).forEach((event) => {
          vnode.componentInstance.$on(event, (eventData) => {
            const targetEvent = allEvents[event];
            vnode.context[targetEvent](
              eventData,
              binding.arg.data,
              binding.arg.block,
              binding.arg.section
            );
          });
        });
      },
      unbind: function (el, binding, vnode) {
        vnode.componentInstance.$off();
      },
    },
  },
  data() {
    return {
      page: pageJson.result,
      modeEdit: false,
      ssb_key:1,
      selected_block:{},
      Allevent: event,
      dkey:1,
    };
  },
  computed:{
    isVisible(){
      var isfund = false
      if (this.$route.name == 'test' || this.$route.name == 'test2'|| this.$route.name == 'test3' || this.$route.name=='page-preview'){
        isfund = true
      }
      return isfund
    }
  },
  methods: {
    _properties: d_properties,

    sectionAddEmpty(page, section, data, blocks) {
      // ajout dynamique de la section
      let order = 0;
      if (section == 0) {
        order = 1;
      } else {
        order = page.indexOf(section) + 1;
      }
      page.splice(order, 0, {
        page: this.ulid,
        title: (Math.random() + 1).toString(36).substring(7),
        sectionorder: "" + order + "",
        blocks,
      });
    },

    // enregistrement de la page à partir de la section
    putPage(){
      let ulidPage = "01FYRYFAWHBHTKSS3BV2RDSMK2"
      for(let section of this.page){
        axios.put(`sections/${section.ulid}`,{
          page:ulidPage,
          blocks: this.page[0].blocks
        })
        .then(resPutPage=>{
          console.log(resPutPage)
        })
        .catch(errPutPage=>console.error(errPutPage))
      }
    }
  },
  watch:{
    selected_block(val){
      console.log({val})
    }
  },
  created(){
    // this.getSectionPage()
  }
};
</script>

<style lang="scss" scoped>
// css MEB
// @import url("./../../../assets/scss/monechappeebelle.scss");
// css PR
@import url("./../../../assets/scss/pr.scss");

//page-preview
.selectSection:hover {
  border: 1px blue solid;
  padding-top: 3rem;
}
.selectSection {
  border-bottom: 1px solid #b66dff;
  padding-top: 3rem;
}
.crud-section {
  position: absolute;
  top: -2rem;
  right: 4rem;
  margin-top: -16px;
  margin-right: -65px;
}
.drag {
  border: 1.5px dashed #bbbbbb;
  width: 100%;
  min-height: 10rem;
  // height: 200px;
}
.crud-section {
  height: 2rem;
  display: none;
}

.crud-section button,
.crud-section .b-dropdown {
  padding: 0px 40px;
}

.selectSection:hover .crud-section {
  display: inherit;
}
</style>

<style>
@import url('https://use.typekit.net/xkb8fqx.css');
</style>